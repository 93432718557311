
import { onMounted, reactive, ref, toRefs, watch } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { dateFormat, numberFormat, mappedStatus } from '@/utils/helper/format';
import { ServicesTypes } from '@/core/data/constance';
import { useRoute } from 'vue-router';
import Swal from 'sweetalert2/dist/sweetalert2.js';

const breadcrumbs = ['Cancel Report', ['Refunding List']];

export default {
  name: 'Commission List',
  components: {
    Datatable,
    ConfirmModal,
  },
  setup() {
    const route = useRoute();
    const paymentMethod = ref<any>(null);
    const tableRef = ref<any>(null);
    const tableHeader = ref<any[]>([]);
    const state = reactive({
      tableData: [],
      loading: false,
      total: 0,
      rowsPerPage: 10,
      currentPage: 1,
    });
    const selectedIds = ref<any[]>([]);

    watch(
      () => [
        tableRef.value?.pagination.rowsPerPage,
        tableRef.value?.pagination.page,
      ],
      async ([rows, page]) => {
        state.currentPage = page;
        state.rowsPerPage = rows;

        if (state.rowsPerPage + 1 > state.total) {
          state.currentPage = 1;
        }

        await fetch(state.currentPage, state.rowsPerPage);
      }
    );

    watch(
      () => route.query,
      async (query) => {
        const { paymentMethod: method } = query;
        paymentMethod.value = method ? `${method}` : null;
        await fetch();
      }
    );

    const setTableHeader = (method) => {
      let tmp = [
        {
          name: '#',
          key: 'checkBox',
          sortable: true,
        },
        {
          name: 'Order ID',
          key: 'id',
          sortable: true,
        },
      ];

      if (!method || method === 'Credit') {
        tmp = [
          ...tmp,
          {
            name: 'Order REF',
            key: 'orderRef',
            sortable: true,
          },
        ];
      }

      tmp = [
        ...tmp,
        {
          name: 'Order Type',
          key: 'type',
          sortable: true,
        },
        {
          name: 'Price',
          key: 'amountPrice',
          sortable: true,
        },
        {
          name: 'Date Created',
          key: 'createdAt',
          sortable: true,
        },
        {
          name: 'Date Started',
          key: 'startDate',
          sortable: true,
        },
        {
          name: 'Date Ended',
          key: 'endDate',
          sortable: true,
        },
        {
          name: 'Status',
          key: 'status',
          sortable: true,
        },
        {
          name: 'More Details',
          key: 'details',
          sortable: false,
        },
      ];

      tableHeader.value = tmp;
    };

    const fetch = async (page = 1, rowsPerPage = 10) => {
      try {
        const queryCondition: any = {
          page,
          pageSize: rowsPerPage,
        };

        if (paymentMethod.value) {
          queryCondition['paymentMethod'] = paymentMethod.value;
        }

        setTableHeader(paymentMethod.value);

        // const { data } = await ApiService.get(
        //   `/order?${new URLSearchParams(queryCondition)}`
        // );

        const { data } = await ApiService.get(
          `/order/waiting-payment-list?${new URLSearchParams(queryCondition)}`
        );

        if (data.status) {
          state.total = data.total;
          state.tableData = data.datas;
        }
        state.loading = false;
      } catch (error) {
        state.loading = false;
      }
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs(
        breadcrumbs[0] as string,
        breadcrumbs[1] as Array<Record<string, unknown> | string>,
        {}
      );
      const { paymentMethod: method } = route.query;
      paymentMethod.value = method ? `${method}` : null;
      await fetch();
    });

    const handleSelected = (e) => {
      e.preventDefault();
      if (e.target.checked) {
        selectedIds.value.push(e.srcElement.dataset.id);
      } else {
        const idx = selectedIds.value.indexOf(e.srcElement.dataset.id);
        if (idx > -1) {
          selectedIds.value.splice(idx, 1);
        }
      }
    };

    const refundOrders = async () => {
      try {
        await ApiService.post(`/order/confirm-payments`, {
          ids: selectedIds.value,
        });

        selectedIds.value = [];

        return Swal.fire({
          text: 'You have successfully refunded order!',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Ok, got it!',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-primary',
          },
        }).then(async () => {
          await fetch();
        });
      } catch (error) {
        Swal.fire({
          text: `cannot refund`,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'ok',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    };

    return {
      ...toRefs(state),
      tableHeader,
      breadcrumbs,
      tableRef,
      dateFormat,
      numberFormat,
      ServicesTypes,
      mappedStatus,
      handleSelected,
      selectedIds,
      refundOrders,
    };
  },
};
